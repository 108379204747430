import React from 'react';
import {Button, FormGroupList, Input, FormGroup, Textarea } from 'tyb';
import { NewSelect } from 'ucode-components';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import { FormGroupField } from 'tyb';
const { getZone } = iceStarkData.store.get('commonAction')?.zone;
const { loadCertificateAdd, loadCertificateEdit, loadCertificateId ,loadCertificateSelections} = iceStarkData.store.get('commonAction')?.certificate;
import enums from '@/assets/enum.json';
import './certificateForm.scss';

// const factoryTypeFilter = [
//   {text: '包材印刷工厂', value: 'PRINT'},
//   {text: '成品灌装工厂', value: 'PACKAGE'},
// ];

const validate = values => {
  const errors = {};
  if (!values.name || values.name && values.name.trim().length == 0) {
    errors.name = "请输入证书名称";
  }
  
  if (values.name && values.name.trim().length > 30) {
    errors.name = "长度不超过30个字符";
  }
  if (values.remark && values.remark.trim().length > 50) {
    errors.remark = '长度不超过50个字符';
  }
  if (!values.certificateType) {
    errors.certificateType = '请选择证书类型';
  }
  if(!values.maxCount){
    errors.maxCount = '请输入可授权工控机数量';
  }
  if(values.maxCount<1){
    errors.maxCount = '可授权工控机数量不能小于1';
  }
  const pattr =/^[0-9]+$/ ;
  if(!pattr.test(values.maxCount)){
    errors.maxCount = '可授权工控机数量必须是整数';
  }

  if(values.maxCount>200){
    errors.maxCount = '可授权工控机数量不能超过200';
  }
  return errors
};

function isInteger(obj) {
  return parseInt(obj, 10) === obj
}
@connect (
  state => {
    return {
      form: state.form,
      ...state.zone,
      ...state.factory,
    };
  },
  {
    getZone: getZone.REQUEST,
    loadCertificateAdd: loadCertificateAdd.REQUEST,
    loadCertificateEdit: loadCertificateEdit.REQUEST,
    loadCertificateId: loadCertificateId.REQUEST,
    loadCertificateSelections:loadCertificateSelections.REQUEST,
  }
)
class FactoryCreateForm extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      cityTree: [],
      countyTree: [],
      certificateTypeArr:Object.keys(enums.certificateType).map(item => { return { value: item, text: enums.certificateType[item].name } })
    };
  }
  componentDidMount () {
    const { dataId, zoneList = [], loadCertificateId ,loadCertificateSelections,initialize} = this.props;
    // console.log(dataId)
    if (zoneList.length < 1) {
      this.props.getZone();
    }
   
    if (dataId) {
      loadCertificateId({
        id: dataId, callback: (res) => {
          res={
            ...res,
            certificateType:this.state.certificateTypeArr.filter(key=>{ return key.value==res.certificateType})[0]

          }
          console.log(res)
          this.initForm(res);
        }
      });
    }else{
      initialize({maxCount:"1"})
    }
    
  };
  componentDidUpdate(prevProps, prevState) {
    const { factoryDetail = {} } = this.props;
    if (Object.keys(factoryDetail).length > 0) {
      if (prevProps.zoneList.length == 0 && this.props.zoneList.length > 0) {
      this.initForm(factoryDetail);
    } 
    }
  };
  initForm = (res) => {
      const { form = {}, initialize } = this.props;
      const { provinceCode = '', cityCode = '' } = res;
      if (provinceCode) {
          this.provinceCodeChange(provinceCode, 2, 'cityTree');
      }
      if (cityCode) {
          this.provinceCodeChange(cityCode, 3, 'countyTree');
      }
      initialize({
          ...form.factoryCreateForm.values,
          ...res
      });
  }

  // 统一去前后空格
  trims = obj => {
    for (let key in obj) {
      if (obj[key]== null) {
      }
      else if (obj[key].constructor === String) {
        obj[key] = obj[key].trim();
      } else if (obj[key].constructor === Object) {
        this.trims(obj[key]);
      }
    }
  }


  handleSubmit = data => {
    const {loadCertificateAdd, loadCertificateEdit, handleClose,factoryId} = this.props;
    console.log(data)
    data={
      ...data,
      factoryId:factoryId,
      certificateType:data.certificateType.value
    }
    this.trims (data);
    if (data.id) { loadCertificateEdit({data, callback: handleClose}); } else { loadCertificateAdd({data, callback: handleClose}); }
  };

  // 选择省，加载市级树
  provinceCodeChange = (value, level, tree) => {
    const { zoneList = [], initialize, form: { factoryCreateForm = {} } } = this.props;
    initialize({ ...factoryCreateForm.values, countyCode: '', cityCode: level == 2 ? '' : value, });
    const newTreeData = zoneList.filter(v => v.parentCode == value && v.level == level);
    let preSetData = {};
    // 没有二级
    if (level == 2 && newTreeData.length == 0) {
      preSetData = { countyTree: [], cityTree: [], countyTree: zoneList.filter(v => v.parentCode == value) }
    } else {
      preSetData = { countyTree: [], [tree]: newTreeData }
    }
    this.setState (preSetData);
  };

  render () {
    const { handleClose, handleSubmit, dataId} = this.props;
    const { zoneList, form: { factoryCreateForm = { value: {provinceCode: '', cityCode: '', countyCode: ''}, }, }, } = this.props;
    const {cityTree, countyTree,certificateTypeArr} = this.state;
    if (!factoryCreateForm.values) { factoryCreateForm.values = { provinceCode: '', cityCode: '', countyCode: '', }; }
    return (
      <div className="factory-form">
        <FormGroupList>
          <FormGroupField
            name={'name'}
            label="证书名称"
            control={Input}
            component={FormGroup}
            required
            placeholder="请输入证书名称"
          >
            <span className="remark-text">长度不超过30个字符</span>
            </FormGroupField>
        <FormGroupField
            name={dataId?'certificateType.value':'certificateType'}
            label="证书类型"
            disabled={dataId}
            list={certificateTypeArr}
            style={{ width: '330px' }}
            component={NewSelect}
            needText={dataId?false:true}
            placeholder="选择证书类型"
            required
          />
        <FormGroupField
            name={'maxCount'}
            label="可授权工控机个数"
            control={Input}
            component={FormGroup}
            required
          />
          <FormGroupField
            name={'remark'}
            label="备注"
            control={Textarea}
            component={FormGroup}
            placeholder="备注"
            text="长度不超过50个字符"
          />
        </FormGroupList>
        <div className="button-box">
          <Button className='' onClick={ handleSubmit(this.handleSubmit) }>提交</Button>
          <Button className='weak' onClick={handleClose}>取消</Button>
        </div>
      </div>
    );
  }
}
export default reduxForm ({
  form: 'factoryCreateForm',
  validate
}) (FactoryCreateForm);
