import React from 'react';
import { connect } from 'react-redux';
import { DataTable, Pager, SearchInput, Button, Dialog, PopEditor, FormGroupList, FormGroup, Input } from 'tyb';
const { loadIpcList, enableOrDisabledIpc, ipcEdit } = iceStarkData.store.get('commonAction')?.certificate;
const { prizePaging, prizeCopy, prizeDelete, changeKeyWords } = iceStarkData.store.get('commonAction')?.prizeManager;
import { handSortOrder } from 'ucode-utils';
import { formatDate, getColorByValue } from 'ucode-utils';
// import { handSortOrder } from 'ucode-utils';
import './certificateIpcList.scss';

@connect(
    state => {
        return {
            ...state.factory,
            permissionIds: state.users.permissionIds || [],
            ipcParam: state.certificate.ipcParam || [],
            ipcList: state.certificate.ipcList || [],
        };
    },
    {
        loadIpcList: loadIpcList.REQUEST,
        enableOrDisabledIpc: enableOrDisabledIpc.REQUEST,
        prizeCopy: prizeCopy.REQUEST,
        ipcEdit: ipcEdit.REQUEST,
    }
)
class certificateIpcList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortId: null,
            dataId: '',

            factoryColumns: [
                {
                    key: 'serialNumber',
                    dataIndex: 'serialNumber',
                    title: '工控机设备串号 ',
                },
                {
                    key: 'name',
                    dataIndex: 'name',
                    title: '设备名称',
                    render: (value, record) => (
                        (
                            <div>
                                <span className="btn-opration btn-oprationPosition">
                                    <a
                                        href="javascript:;"
                                        onClick={() => { this.setState({ name: value, remarkRule: {} }); this.hiddenPop(`${record.id}copy`); }}
                                    >  <div>
                                            <span >{value}</span>
                                            <i style={{ position: 'absolute' }} className="pencil-icon"></i>
                                        </div>
                                    </a>
                                    {(this.state.visiableId === `${record.id}copy`) &&
                                        <div
                                            className={`myself-popeditor tc-15-confirm-popout`}
                                            style={{ right: 0, height: '180px' }}
                                        >
                                            <PopEditor
                                                onOk={() => { this.oprations(record.id, record.version); }}
                                                onCancel={() => { this.hiddenPop(); }}
                                            >
                                                <div className="tc-15-confirm-popout-bd">
                                                    <div>
                                                        <FormGroupList>

                                                            <FormGroup
                                                                className="table-remark"
                                                                style={{ width: '240px' }}
                                                                placeholder="请输入工控机设备"
                                                                name="name"
                                                                control={Input}
                                                                value={this.state.name}
                                                                required
                                                                onChange={(value) => {
                                                                    this.remarkChange(value)
                                                                }}
                                                                text={this.state.remarkRule.error}
                                                                meta={this.state.remarkRule}
                                                            >
                                                            </FormGroup>
                                                        </FormGroupList>
                                                    </div>
                                                </div>
                                            </PopEditor>
                                        </div>
                                    }
                                </span>
                            </div>
                        )
                    ),
                },

                {
                    key: 'createdTime',
                    dataIndex: 'createdTime',
                    title: '激活时间',
                    render: (value) => <div title={formatDate(value)}>
                        <span className="text-overflow">{formatDate(value)}</span>
                    </div>
                },
                {
                    key: 'netWorkType',
                    dataIndex: 'netWorkType',
                    title: '网络状态',
                    render:(value)=>{
                    return<div>{value=="ON_LINE"?"在线":"离线"}</div>
                    }
                },

                {
                    key: 'status',
                    dataIndex: 'status',
                    title: '操作',
                    render: (value, record) => {
                        return (
                            <div className="table-operate-btn" style={{ color: '#006EFF' }}>
                                {this.props.permissionIds.includes('data.basicData.factory.status') && <span onClick={() => {
                                    this.props.enableOrDisabledIpc({ id: record.id, status: value == 'ENABLE' ? 'DISABLE' : 'ENABLE', factoryId: this.props.dataId })
                                }}>
                                    {value == 'ENABLE' ? '禁用' : '启用'}
                                </span>}
                            </div>
                        );
                    },
                },
            ],
        };
    }

    componentDidMount() {
        const { loadIpcList, ipcParam, factoryId, dataId } = this.props;
        loadIpcList({ ...ipcParam, factoryId: factoryId, certCode: dataId });
    }
    // 操作按钮触发事件
    oprations = (id, version) => {
        const { ipcEdit, factoryId,dataId } = this.props;
        // debugger
        ipcEdit({ data: { id: id, version: version, name: this.state.name }, factoryId: factoryId,certCode: dataId})
        this.hiddenPop()
    };
    hiddenPop = (value = '') => {
        this.setState({ visiableId: value });
    };
    changePage = (value, tag) => {
        const { loadIpcList, dataId, ipcParam, factoryId } = this.props;
        const obj = tag == 'perpage' ? { ipcParam: { ...ipcParam }, page: 0, size: value || 20 } : { ipcParam: { ...ipcParam }, page: value };
        loadIpcList({ ipcParam: { ...obj, factoryId: factoryId, certCode: dataId } });
    };
    remarkChange = (e) => {
        const { remarkRule } = this.state;
        let remarkValue = e.target.value || '';
        if (!remarkValue || (remarkValue && remarkValue.trim().length == 0)) {
            remarkRule.error = "请输入工控机设别号";
        } else if (remarkValue && remarkValue.trim().length > 30) {
            remarkRule.error = "长度不超过30个字符";
        } else {
            remarkRule.error = "";
        }
        this.setState({ name: remarkValue, remarkRule });
    }
    downloadCertificate = (record) => {
        alert(record)

    };
    tableHeadFilter = param => {
        const { list, titleContent, value, filterFun } = param;
        return (
            <DataTable.FilterableColumnHeader
                list={list}
                value={value}
                onFilter={value => {
                    filterFun(value);
                }}
            >
                {titleContent}
            </DataTable.FilterableColumnHeader>
        );
    };
    handleSort = (curOrder, key) => {
        const { ipcParam = {}, loadIpcList, factoryId, dataId } = this.props;
        const newOrder = handSortOrder(curOrder);
        loadIpcList({ ipcParam: { ...ipcParam, certId: dataId, factoryId: factoryId, page: 0, sort: `${key},${newOrder}`, }, });
        this.setState({ sortId: newOrder });
    };

    // 排序 sort
    tableHeadSort = col => {
        return (
            <DataTable.SortableColumnHeader
                order={this.state.sortId}
                sortKey={col.key}
                onSort={() => { this.handleSort(this.state.sortId, col.key); }}
            >
                {col.title}
            </DataTable.SortableColumnHeader>
        );
    };
    handleClose = () => {
        this.props.certificateIpcList = false;

    }
    render() {
        const { dataId, factoryColumns } = this.state;
        const { ipcParam, factoryPageListLoading, handleClose, ipcList } = this.props;
        return (
            <div className={`factory-container ${ipcList.length > 0 ? 'table-tr-height50' : 'table-tr-middle'}`}>

                {/* top-bar */}
                {/* 列表，分页 */}
                <DataTable
                    columns={factoryColumns}
                    data={ipcList}
                    rowKey="id"
                    rowClassName={(record) => record.status == 'DISABLE' ? 'table-gray-row' : ''}
                    loading={factoryPageListLoading}
                    style={{ width: '100%', maxWidth: '100%', }} messageRender={({ data, error }) => {
                        if (error != null) { return error.msg; }
                    }}
                />
                {/* {ipcList.length > 0 &&
                    <div className="page-padding20">
                        <Pager
                            total={ipcParam.totalElements}
                            current={ipcParam.page}
                            perPageCount={ipcParam.size}
                            onPageChange={value => { this.changePage(value, 'page'); }}
                            onPerPageChange={value => { this.changePage(value, 'perpage'); }}
                        />
                    </div>} */}
                <div className="button-box button-boxcertificate">
                    <Button className='weak' onClick={handleClose}>关闭</Button>
                </div>
            </div>
        );
    }
}

export default certificateIpcList;
