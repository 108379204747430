import React from 'react';
import { connect } from 'react-redux';
import { DataTable, Pager, SearchInput, Button, Dialog } from 'tyb';
const { loadCertificateList, enableOrDisabledCertificate,exportCertificate } = iceStarkData.store.get('commonAction')?.certificate;
import enums from '@/assets/enum.json';
import CertificateForm from './components/certificateForm/certificateForm';
import CertificateIpcList from './components/certificateIpcList/certificateIpcList';
import queryString from "query-string";
import { handSortOrder } from 'ucode-utils';
// import { ajax,ajax_get } from '@/redux/ajax';
// import urls from '@/api/urls';
import "./index.scss";

// const factoryTypeFilter = [
//   { text: '包材印刷工厂', value: 'PRINT' },
//   { text: '成品灌装工厂', value: 'PACKAGE' },
// ];

@connect(
  state => {
    return {
      // ...state.factory,
      permissionIds: state.users.permissionIds || [],
      certificateListParam: state.certificate.certificateListParam || [],
      certificateList: state.certificate.certificateList || [],
    };
  },
  {
    loadCertificateList: loadCertificateList.REQUEST,
    enableOrDisabledCertificate: enableOrDisabledCertificate.REQUEST,
    exportCertificate:exportCertificate.REQUEST,
  }
)
class FactoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortId: null,
      dataId: '',
      certificateFormForm: false,
      certificateIpcList: false,
      parsed:"",
      factoryColumns: [
        {
          key: 'id',
          dataIndex: 'name',
          title: '证书名称/ID',
          render: (value, record) => {
            return (
              <div >
                <div>{value}</div>
                <div>{record.id}</div>
              </div>
            );
          },
        },
        {
          key: 'code',
          dataIndex: 'code',
          title: '证书编号',
          render: (value, record) => (
            <div className="moreline-text-ellipsis" title={`${record.provinceName || ''}${record.cityName || ''}${record.countyName || ''}${value || ''}`}> {record.provinceName || ''}{record.cityName || ''}{record.countyName || ''}{value || ''} </div>
          ),
        },
        {
          key: 'certificateType',
          dataIndex: 'certificateType',
          title: '证书类型',
          render:(value)=>{
            return <div>{enums.certificateType[value]?enums.certificateType[value].name:''}</div>
           
          }
        },
        {
          key: 'maxCount',
          dataIndex: 'maxCount',
          title: '授权工控机个数',
          render: (value, record) => (
            <div > {record.usedCount || "0"}/{value|| ''}</div>
          ),
       
        },
        {
          key: 'remark',
          dataIndex: 'remark',
          title: '备注',
        },
        {
          key: 'status',
          dataIndex: 'status',
          title: '操作',
          render: (value, record) => {
            return (
              <div className="table-operate-btn" style={{ color: '#006EFF' }}>
                {this.props.permissionIds.includes('data.basicData.factory.edit') && <span onClick={() => { this.setState({ certificateFormForm: true, dataId: record.id }); }}>编辑</span>}
                {this.props.permissionIds.includes('data.basicData.factory.edit') && <span onClick={() => { this.setState({ certificateIpcList: true, dataId: record.code}); }}>工控机</span>}
                {this.props.permissionIds.includes('data.basicData.factory.edit') && <span onClick={() => { this.downloadCertificate(record); }}>下载</span>}
                {this.props.permissionIds.includes('data.basicData.factory.status') && <span onClick={() => {
                  this.props.enableOrDisabledCertificate({ id: record.id, status: value == 'ENABLE' ? 'DISABLE' : 'ENABLE',factoryId: this.state.parsed })
                }}>
                  {value == 'ENABLE' ? '禁用' : '启用'}
                </span>}
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {

    const { loadCertificateList, certificateListParam, location } = this.props;
    const parsedId =  queryString.parse(location.search);
    this.setState({
      parsed:parsedId.id
    })
    loadCertificateList({ ...certificateListParam,factoryId:parsedId.id});
  
  }

  changePage = (value, tag) => {
    const { loadCertificateList, certificateListParam } = this.props;
    const obj = tag == 'perpage' ? { ...certificateListParam, page: 0, size: value || 20 } : { ...certificateListParam, page: value };
    loadCertificateList({ certificateListParam: { ...obj,factoryId:parsedId} });
  };
  downloadCertificate = (record) => {
    console.log(record.path)
    if(record.path){
      window.location.href =record.path
    }
  };
  tableHeadFilter = param => {
    const { list, titleContent, value, filterFun } = param;
    return (
      <DataTable.FilterableColumnHeader
        list={list}
        value={value}
        onFilter={value => {
          filterFun(value);
        }}
      >
        {titleContent}
      </DataTable.FilterableColumnHeader>
    );
  };
  handleSort = (curOrder, key) => {
    const { certificateListParam = {}, loadCertificateList } = this.props;
    const newOrder = handSortOrder(curOrder);
    loadCertificateList({ certificateListParam: { ...certificateListParam,factoryId:this.state.parsed, page: 0, sort: `${key},${newOrder}`, }, });
    this.setState({ sortId: newOrder });
  };

  // 排序 sort
  // tableHeadSort = col => {
  //   return (
  //     <DataTable.SortableColumnHeader
  //       order={this.state.sortId}
  //       sortKey={col.key}
  //       onSort={() => { this.handleSort(this.state.sortId, col.key); }}
  //     >
  //       {col.title}
  //     </DataTable.SortableColumnHeader>
  //   );
  // };
  tableHeadInit = () => {
    return this.state.factoryColumns.map(column => {
      return column;
      // if (column.key === 'id') {
      //   return {
      //     ...column,
      //     headerRender: col => this.tableHeadSort(col),
      //   };
      // }else {

      // }
    });
  };
  
  exportCertificat(){
    // let urlResponse = ajax_get(`${ urls.code.certificate.certificate}/software/download?name=${"LicenseInstaller"}`);
    // urlResponse.then(function(res){
    //   console.log(res)
    //   window.location.href =res

    // })
    this.props.exportCertificate({name:"LicenseInstaller",callback(res){
      // console.log(res)
      window.location.href =res.response.softUri
      
    }})
  }
  exports=()=>{
    // let urlResponse = ajax_get(`${ urls.code.certificate.certificate}/software/download?name=${"DataViewer"}`);
    // urlResponse.then(function(res){
    //   console.log(res)
    //   window.location.href =res

    // })
    console.log( this.props.exportCertificate)
    this.props.exportCertificate({name:"DataViewer",callback(res){
      window.location.href =res.response.softUri
    }})
  }
  render() {
    const { certificateFormForm, dataId, certificateIpcList,parsed } = this.state;
    const { certificateList = [], certificateListParam, factoryPageListLoading, loadCertificateList, location } = this.props;
    const factoryId = queryString.parse(location.search).id;
    return (
      <div className={`factory-container ${certificateList.length > 0 ? 'table-tr-height50' : 'table-tr-middle'}`}>

        {/* top-bar */}
        <div className="factory-topbar">
          {this.props.permissionIds.includes('data.basicData.factory.add') && <Button className="tc-15-btn" style={{ marginRight: 10 }} onClick={() => { this.setState({ certificateFormForm: true, dataId: '' }); }}>创建证书</Button>}
          {this.props.permissionIds.includes('data.basicData.factory.add') && <Button className="tc-15-btn weak" style={{ marginRight: 10 }} onClick={() => { this.exportCertificat() }}>证书软件下载</Button>}
          {this.props.permissionIds.includes('data.basicData.factory.add') && <Button className="tc-15-btn weak" onClick={() => {this.exports() }}>解码软件下载</Button>}

        </div>
        {/* 列表，分页 */}
        <DataTable
          columns={this.tableHeadInit()}
          data={certificateList}
          rowKey="id"
          rowClassName={(record) => record.status == 'DISABLE' ? 'table-gray-row' : ''}
          loading={factoryPageListLoading}
          style={{ width: '100%', maxWidth: '100%', }} messageRender={({ data, error }) => {
            if (error != null) { return error.msg; }
            if (data == null || data.length === 0) {
              return (
                <div className="text-weak">
                  暂无证书{this.props.permissionIds.includes('data.basicData.factory.add') ? "，您可以点击左上角" : null}
                  {this.props.permissionIds.includes('data.basicData.factory.add') && <span style={{ color: '#006EFF' }} onClick={() => { this.setState({ certificateFormForm: true, dataId: '' }); }} > 创建证书 </span>}
                </div>
              );
            }
          }}
        />
        {certificateList.length > 0 &&
          <div className="page-padding20">
            <Pager
              total={certificateListParam.totalElements}
              current={certificateListParam.page}
              perPageCount={certificateListParam.size}
              onPageChange={value => { this.changePage(value, 'page'); }}
              onPerPageChange={value => { this.changePage(value, 'perpage'); }}
            />
          </div>}

        {certificateFormForm && <Dialog
          title={dataId ? "编辑证书" : "创建证书"}
          visible={certificateFormForm}
          onCancel={() => { this.setState({ certificateFormForm: false }); }}
          hasFooter={false}
          
          className="factory-modal"
        >
          <CertificateForm
            dataId={dataId}
            factoryId={factoryId}
            handleClose={() => {
              this.setState({ certificateFormForm: false });
            }}
          />
        </Dialog>}
        {certificateIpcList && <Dialog
          title={"工控" + dataId}
          visible={certificateIpcList}
          onCancel={() => { this.setState({ certificateIpcList: false }); }}
          hasFooter={false}
          className="factory-modal"
          style={{ width: "987px" }}
        >
          <CertificateIpcList
            dataId={dataId}
            factoryId={factoryId}
            handleClose={() => {
              this.setState({ certificateIpcList: false });
            }}
          />
        </Dialog>}

      </div>
    );
  }
}

export default FactoryList;
